// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lifestyle-tsx": () => import("./../../../src/pages/lifestyle.tsx" /* webpackChunkName: "component---src-pages-lifestyle-tsx" */),
  "component---src-pages-residences-tsx": () => import("./../../../src/pages/residences.tsx" /* webpackChunkName: "component---src-pages-residences-tsx" */),
  "component---src-templates-residence-detail-tsx": () => import("./../../../src/templates/residence-detail.tsx" /* webpackChunkName: "component---src-templates-residence-detail-tsx" */)
}

