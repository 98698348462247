exports.shouldUpdateScroll = ({routerProps: {location}}) => {
  const {pathname} = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/`,
    `/design`,
    `/residences`,
    `/contact`,
    `/lifestyle`,
  ];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    document.getElementsByTagName('body')[0].scrollIntoView();
  }

  return false;
};
